import React from 'react';
import './Split.css';

const Split = () => {
    return (
        <>
        <div id="split">
            <span></span>
        </div>
        </>
    );
}

export default Split;